import * as Sentry from '@sentry/browser';
import version from './version';
import { apps, cross } from './mf-applications';
import { registerApplication } from 'single-spa';

export function importCrossApps() {
	return cross.reduce((promiseChain, { name }) => {
		return promiseChain.then(() => {
			return loadTypeCross({ name });
		});
	}, Promise.resolve());
}

function loadTypeCross(app) {
	const { name } = app;
	return System.import(name)
		.then(module => {
			if (name === '@superpagos/mf-store') {
				// update root version
				const { brandReducer } = module;
				brandReducer.setGlobalVersion({ app: 'root', version });
			}
			return module;
		})
		.catch(err => {
			handleModuleLoadError(err, name);
		});
}

export const registerMicrofrontend = () => {
	for (const mfApplication of apps) {
		loadTypeApp(mfApplication);
	}
};

function loadTypeApp(app) {
	const { name, moduleId, activeWhen } = app;

	registerApplication({
		name,
		app: () =>
			System.import(moduleId).catch(err => {
				handleModuleLoadError(err, name);
			}),
		activeWhen
	});
}

const handleModuleLoadError = (error, trace) => {
	console.error(`❌ ~ loading app: ${trace} ~ err:`, error);

	// send error to Sentry
	Sentry.captureException(error);

	// Clear the cache
	if ('caches' in window) {
		caches.keys().then(names => {
			for (let name of names) caches.delete(name);
		});
	}

	// Build the error element
	buildErrorElement();
};

let errorElement = null;

function buildErrorElement() {
	if (errorElement) return;
	// Create a new div element
	const wrapper = document.createElement('div');
	wrapper.className =
		'fixed inset-0 flex items-center justify-center text-2xl p-4 text-center';

	const container = document.createElement('div');
	container.className = 'flex flex-col items-center';

	// brand logo
	const logo = document.createElement('img');
	logo.src = 'https://assets.refacil.co/brands/logoRefacil.png';
	logo.alt = 'Refacil';
	logo.className = 'w-[180px] mb-4';

	// Create the message element
	const message = document.createElement('div');
	message.textContent =
		'Ocurrio un error inesperado. Por favor recarga la página.';

	// Create the image element
	const imgWrapper = document.createElement('div');
	imgWrapper.className = 'mb-4 w-[340px] ml-auto mr-auto';

	const img = document.createElement('img');
	img.src = './img/module_error.png';
	img.alt = 'Error';

	imgWrapper.appendChild(img);

	// Create the button element
	const button = document.createElement('button');
	button.textContent = 'Volver a cargar';
	button.className =
		'mt-4 px-4 py-2 bg-[#00ceee] text-white rounded hover:bg-[#1cb2c9] text-lg';
	button.addEventListener('click', () => {
		location.reload();
	});

	// Append the message, image, and button elements to the wrapper
	container.appendChild(logo);
	container.appendChild(imgWrapper);
	container.appendChild(message);
	container.appendChild(button);

	wrapper.appendChild(container);

	// Append the wrapper to the body
	document.body.appendChild(wrapper);

	errorElement = wrapper;
}
