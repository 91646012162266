import { start } from 'single-spa';
import { initSentry } from './boot-sentry';
import { importCrossApps, registerMicrofrontend } from './utils';

initSentry();

importCrossApps().then(() => {
	registerMicrofrontend();
	start();
});
