const cross = [
	{
		name: '@superpagos/mf-services'
	},
	{
		name: '@superpagos/mf-store'
	},
	{
		name: '@superpagos/mf-custom-components'
	}
];

const apps = [
	{
		name: '@superpagos/mf-navbar',
		moduleId: '@superpagos/mf-navbar',
		activeWhen: '/'
	},
	{
		name: '@superpagos/mf-sells',
		moduleId: '@superpagos/mf-sells',
		activeWhen: '/sells'
	},
	{
		name: '@superpagos/mf-pays',
		moduleId: '@superpagos/mf-pays',
		activeWhen: '/refacilpay'
	},
	{
		name: '@superpagos/mf-reports',
		moduleId: '@superpagos/mf-reports',
		activeWhen: '/reports'
	},
	{
		name: '@superpagos/mf-credits',
		moduleId: '@superpagos/mf-credits',
		activeWhen: '/credits'
	}
];

export { cross, apps };
