import * as Sentry from '@sentry/browser';
import version from './version';

export const initSentry = () => {
	// eslint-disable-next-line no-undef
	if (process.env.NODE_ENV !== 'production') return;
	Sentry.init({
		dsn: 'https://75e4550142d2bbe4b83a70eb77c0ca6e@o4507165763895296.ingest.us.sentry.io/4507226911997952',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration()
		],
		release: version,
		// Performance Monitoring
		tracesSampleRate: 0.1, //  Capture 10% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			/^https:\/\/app\.refacil\/\.co/,
			/^https:\/\/app\/\.qa\.refacil\/\.co/
		],
		// Session Replay
		replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.// If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
};
